import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ux/uxcore2';
import Search from '@ux/search';
import '@ux/search/styles';
import { provideContext } from './../util/storeProvider';
import DomainResultsComponent from './DomainResultsComponent';
import traffic from './../util/traffic';
import ChevronR from '@ux/icon/chevron-right';
import SearchIcon from '@ux/icon/search';
import { css } from '@linaria/core';
const domainSearch = css`
  & .ux-text-entry-shell.ux-text.ux-text-input {
    padding: 16px;
    margin: 0;
    border: 0;
    width: 100%;
    height: 100%;
    .ux-text-entry-field{
      margin: 0;
      height: 100%;
      width: 100%;
      padding:0 16px;
    }
  }

`;
class DomainSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    traffic.addPageRequest('domainsearch');
    if (this.inputRef.current?.formElement?.elements?.input) {
      this.inputRef.current.formElement.elements.input.focus();
    }
  }

  handleContinue() {
    this.context.store.handleContinueClick();
  }

  handleSearch() {
    const value  = this.inputRef.current.value;
    traffic.addEvent({ eid: this.context.store.getEid('domainsearch', 'submit', 'click') });
    this.context.store.domainSearchApi.search({
      query: value,
      apiKey: this.props.groupContent.apiKey,
      tmsKey: this.props.groupContent['tms-key']
    });
  }

  handleChange(value) {
    const splitValue = value.split('.');
    const sldMaxCharacters = 64;
    if (splitValue[0].length >= sldMaxCharacters) {
      splitValue[0] = splitValue[0].substr(0, sldMaxCharacters - 1);
      this.inputRef.current.setStateAndClose({ value: splitValue.join('.') });
    }
  }

  render() {
    const { context: { store }, props } = this;
    const {
      groupContent = {},
      commonContent = {},
      disabled
    } = props;

    return (
      <div className='mt-3'>
        <div className={ `domain-search ${domainSearch}` }>
          <Search
            id='domainSearch'
            disabled={ disabled }
            className='flew-row'
            onChange={ this.handleChange }
            ref={ this.inputRef }
            data-eid={ store.getEid('domain_name', 'button', 'submit') }
            placeholder={ groupContent['domain-search-placeholder'] }
            design='primary'
            clearable={ false }
            button={
              <Button
                text={ groupContent['domain-search-button'] }
                design='primary'
                onClick={ this.handleSearch }
                icon={ <SearchIcon /> } />
            }
          />
        </div>
        <div className='row text-right' ref={ this.continueButtonDiv }>
          <Button
            className='no-thanks-button'
            data-eid={ this.context.store.getEid('continue', 'submit', 'click') }
            design='inline'
            onClick={ this.handleContinue }
            disabled={ disabled }
            icon={ <ChevronR width={ 16 } height={ 16 } /> }
            text={ commonContent.noThanksOnFreeOffer }
          />
        </div>
        <DomainResultsComponent
          disabled={ disabled }
          content={ groupContent }
          includeState={ [
            { path: 'domainSearch', propKey: 'domainSearch' }
          ] }
        />
      </div>
    );
  }
}

DomainSearchComponent.propTypes = {
  commonContent: PropTypes.object,
  groupContent: PropTypes.object,
  disabled: PropTypes.bool
};

export default provideContext(DomainSearchComponent);
