import PropTypes from 'prop-types';
import { PureComponent, Fragment }  from 'react';
import GroupComponent from './GroupComponent';
import OrderSummaryComponent from './OrderSummaryComponent';
import DisclaimerComponent from './DisclaimerComponent';
import StickyComponent from './StickyComponent';
import { StickyContainer } from 'react-sticky';
import { provideContext } from './../util/storeProvider';
import { getInstancePathKey } from './../util/common';

export class PageComponent extends PureComponent {
  render() {
    const {
      colClass,
      showOrderSummary,
      continueButtonDiv,
      scrollToCartText,
      currentPage,
      pages
    } = this.props;

    if (pages.length === 0 || Object.keys(pages).length === 0) return null;

    const { page: pageName, groups, content } = pages[currentPage];
    const title = { __html: content.title };
    const description = { __html: content.description };
    return (
      <Fragment>
        { title && <h2 dangerouslySetInnerHTML={ title }/> }
        { description && <p dangerouslySetInnerHTML={ description }/> }
        <StickyContainer>
          <div className='row'>
            <div className={ colClass }>
              {
                groups &&
                groups.filter(group => group.layoutProperties.display !== 'none')
                  .filter(group => group.attributes.length > 0)
                  .map(group => {

                    return <GroupComponent
                      includeState={ [{ path: 'app.postComplete' }, { path: 'app.content', propKey: 'commonContent' }] }
                      key={ `${pageName}.${ getInstancePathKey(group.group, group.attributes[0].propertytype) }` }
                      { ...group }
                      pageName={ pageName }
                    />;
                  })
              }
            </div>
            {
              showOrderSummary &&
              <div className='col-sm-12 col-xl-4 order-summary'>
                <StickyComponent
                  continueButtonDiv={ continueButtonDiv }
                  text={ scrollToCartText }>
                  <OrderSummaryComponent
                    includeState={ [{ path: 'orderSummary' }, { path: 'app.content', propKey: 'commonContent' }] }
                    content={ content }
                    groups={ groups }
                  />
                </StickyComponent>
              </div>
            }
          </div>
        </StickyContainer>
        <DisclaimerComponent { ...content }/>
        { this.props.continueButton }

      </Fragment>
    );
  }
}

PageComponent.propTypes = {
  scrollToCartText: PropTypes.string,
  showOrderSummary: PropTypes.bool,
  continueButton: PropTypes.object,
  continueButtonDiv: PropTypes.object,
  colClass: PropTypes.string,
  currentPage: PropTypes.number,
  pages: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  content: PropTypes.shape({
    'product-name': PropTypes.string,
    'product-description': PropTypes.string,
    'title': PropTypes.string,
    'description': PropTypes.string
  })
};

export default provideContext(PageComponent);
