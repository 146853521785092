import { Component } from 'react';
import { provideContext } from '../util/storeProvider';
import TextInput from '@ux/text-input';
import '@ux/text-input/styles';

import PropTypes from 'prop-types';

export class PriceOverrideComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      currentPrice: '',
      failure: null,
      min: this.props.minPricePerMonthFormatted ? Number(this.props.minPricePerMonthFormatted.replace(/[^0-9.-]+/g, '')) : '',
      max: this.props.maxPricePerMonthFormatted ?  Number(this.props.maxPricePerMonthFormatted.replace(/[^0-9.-]+/g, '')) : ''
    };
  }

  validate(value) {
    let failureMessage = null;
    let isValid = true;

    if (value < this.state.min || value > this.state.max) {
      failureMessage = this.props.content['error-message'];
      isValid = false;
    }

    this.setState({
      failure: failureMessage
    });

    return isValid;
  }

  handleChange(value) {
    this.setState({ currentPrice: value });
  }

  handleBlur(value) {
    if (value && value < this.state.min) {
      value = this.state.min;
    } else if (value && value > this.state.max) {
      value = this.state.max;
    }

    // Ensure decimal precision is at most hundreths
    // Note: JavaScript uses floating point math which can lead to rounding errors:
    //       Because we need an exact result with two decimal places
    //       we must multiply your numbers with 100 before the operation
    //       and then divide again afterwards
    if (value % 1) {
      value = value - (((value * 100) % 1) / 100);
      this.handleChange(value);
    }

    this.validate(value);
    this.props.handleChange(value);
  }

  render() {
    const {
      minPricePerMonthFormatted,
      maxPricePerMonthFormatted,
      commonContent
    } = this.props;

    const minText = commonContent.minPrice && commonContent.minPrice.replace('{0}', minPricePerMonthFormatted);
    const maxText = commonContent.maxPrice && commonContent.maxPrice.replace('{0}', maxPricePerMonthFormatted);
    const rangeText = minText + ' - ' + maxText;

    return (
      <TextInput
        label={ rangeText }
        type='number'
        notice={ this.props.content['disclaimer-text'] }
        invalid={ !this.validate }
        errorMessage={ !this.validate ? this.state.failure : '' }
        onBlur={ (e) => this.handleBlur(e.target.value) }
        onChange={ (e) => this.handleChange(e.target.value) }
        value={ this.state.currentPrice }
        name={ this.props.instancePath }
      />
    );
  }
}

PriceOverrideComponent.propTypes = {
  instancePath: PropTypes.string,
  layoutProperties: PropTypes.object,
  includeState: PropTypes.array,
  content: PropTypes.object,
  selectedVal: PropTypes.object,
  maxPricePerMonthFormatted: PropTypes.string,
  minPricePerMonthFormatted: PropTypes.string,
  commonContent: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default provideContext(PriceOverrideComponent);
