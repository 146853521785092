import PropTypes from 'prop-types';
import { Component } from 'react';
import App from './components/App';
import { Provider } from './util/storeProvider';
import debounce from 'lodash.debounce';
import { getBreakpoint, isMobile } from './util/breakpoint';
import './styles/globals';

class ConfigPage extends Component {
  constructor(props) {
    super(props);
    this.state = { store: this.props.store };
    this.state.postComplete = this.props.store.getState('app').postComplete;
    this.state.breakpoint = getBreakpoint();
    this.state.isMobile = isMobile();
    this.state.isBrowser = (typeof window !== 'undefined');
    this.postCompleteFocus;

    this.subscriptionId;
    this.onStoreAppChange = this.onStoreAppChange.bind(this);
    this.handleResize = debounce(this.handleResize.bind(this), 100);
  }

  onStoreAppChange(newState) {
    if (!newState.postComplete) {
      this.postCompleteFocus = document.activeElement;
    }

    this.setState({ postComplete: newState.postComplete }, () => {
      if (this.state.postComplete) {
        if (this.postCompleteFocus &&
          typeof this.postCompleteFocus.focus === 'function') {
          this.postCompleteFocus.focus();
        }
        this.postCompleteFocus = null;
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.subscriptionId = this.props.store.subscribe('app', this.onStoreAppChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.props.store.unsubscribe(this.subscriptionId);
  }

  handleResize() {
    this.setState({ breakpoint: getBreakpoint() });
  }

  render() {
    return (
      <Provider value={ this.state }>
        <App includeState={ [{ path: 'app' }] }/>
      </Provider>
    );
  }
}

ConfigPage.propTypes = {
  store: PropTypes.object
};

export default ConfigPage;
