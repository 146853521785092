import { utils } from '@ux/uxcore2';
import config from './../config/config';
import set from 'lodash.set';
import get from 'lodash.get';
import querystring from 'querystring';
import { formatMicrounits } from './currencyFormatter';
import { getUpdatedMergedStateObjects } from './common';
import getOrderSummary from './orderSummaryData';
import DomainSearchApi from './domainSearchApi';
import mapData from './dataObject';
import traffic from './traffic';
import parse from 'html-react-parser';
import urlParams from './urlParams';

const _options = {
  headers: {
    'Content-Type': 'application/json'
  },
  credentials: 'include'
};

class StateApi {
  constructor() { // eslint-disable-line no-unused-vars
    this.dataObject = {};
    this.state = {
      data: {},
      orderSummary: {
        totalPrice: 0,
        selections: [],
        termType: '',
        commonContent: {}
      },
      domainSearch: {
        apiKey: null,
        tmsKey: null,
        selectedDomain: {},
        exactDomain: {},
        domains: [],
        searchValue: ''
      },
      app: {
        currentPage: 0,
        error: false,
        initial: null,
        postComplete: false,
        showOrderSummary: true,
        showNoThanksFreeOfferButton: false
      },
      pages: []
    };
    this.nextState = {};
    this.state.constants = {};
    this.getStateValue = this.getStateValue.bind(this);

    this.subscriptions = {};
    this.lastSubscriptionId = 0;
    this.tryRedirect = this.tryRedirect.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.domainSearchApi = new DomainSearchApi(this);
    this.initProps = {};

    return {
      initialize: this.initialize.bind(this),
      subscribe: this.subscribe.bind(this),
      unsubscribe: this.unsubscribe.bind(this),
      formatMicrounits: this.formatMicrounits.bind(this),
      updateInstance: this.updateInstance.bind(this),
      domainSearchApi: this.domainSearchApi,
      handleContinueClick: this.handleContinueClick.bind(this),
      getEid: this.getEid.bind(this),
      parseHtml: this.parseHtml.bind(this),
      getTermData: this.getTermData.bind(this),
      getTermDataByPackageId: this.getTermDataByPackageId.bind(this),
      getPageVariable: this.getPageVariable.bind(this),
      getState: this.getState.bind(this),
      getStateValue: this.getStateValue.bind(this),
      getDataByInstancePath: this.getDataByInstancePath.bind(this),
      getInstanceByInstancePath: this.getInstanceByInstancePath.bind(this),
      mergeWithState: this.mergeWithState.bind(this),
      mergeWithChildState: this.mergeWithChildState.bind(this),
      initProps: this.initProps
    };
  }

  tryRedirect() {
    const pageVars = this.getPageVariable();
    const { redirectUrl, redirectMsTimeout = 15000, content: { redirectMessage } } = pageVars;
    const canRedirect = (redirectUrl && redirectUrl.length > 0 && redirectMessage && redirectMessage.length > 0);
    const _this = this;
    if (canRedirect) {
      setTimeout(function () {
        _this.goToNextPage(redirectUrl);
      }, parseInt(redirectMsTimeout, 10));
      return true;
    }
    return false;
  }

  initialize(initProps, rawDataResponse) {
    if (rawDataResponse === null) return; // eslint-disable-line

    this.initProps = initProps;

    const environment = this.normalizeEnvironment(initProps && initProps.environment);
    const pageVars = this.getPageVariable();
    const configVals = config[environment];
    const goDaddyHostname = /^(?:[A-Za-z]+\.)?(?:dev|test)?-?godaddy\.com$/;
    const isGoDaddy = global.location &&
      global.location.hostname &&
      goDaddyHostname.test(global.location.hostname);
    const plid = !isGoDaddy && (initProps && initProps.plid || pageVars.plid) || 1;
    const hostname = plid > 1 ? 'secureserver.net' : 'godaddy.com';
    const searchParams = querystring.parse(
      global.location &&
      global.location.search &&
      global.location.search.substring &&
      global.location.search.substring(1)
    ); // use uxcore

    const {
      packageId = searchParams.plan || initProps.packageId, // eslint-disable-line no-use-before-define
      /* eslint-disable camelcase */
      rp_personalization = null,
      hivemind = null,
      sf_optimizations = null,
      /* eslint-enable camelcase */
      src = null,
      term = null,
      contextState = null,
      configdevmode = null,
      itc = null,
      xs = '1',
      options = null,
      marketid,
      atb = initProps.atb, // eslint-disable-line no-use-before-define
      oconfig = false
    } = searchParams;

    const hideCrossSells = xs !== '1';

    let layoutUrl = configVals.getLayoutUrl(hostname, plid);
    layoutUrl += packageId;
    /* eslint-disable camelcase */
    layoutUrl += rp_personalization ? `&rp_personalization=${rp_personalization}` : '';
    layoutUrl += hivemind ? `&hivemind=${hivemind}` : '';
    layoutUrl += sf_optimizations ? `&sf_optimizations=${sf_optimizations}` : '';
    /* eslint-enable camelcase */
    layoutUrl += itc ? `&itc=${itc}` : '';
    layoutUrl += src ? `&src=${src}` : '';
    layoutUrl += term ? `&term=${term}` : '';
    layoutUrl += contextState ? `&contextState=${contextState}` : '';
    layoutUrl += configdevmode ? `&configdevmode=${configdevmode}` : '';
    layoutUrl += hideCrossSells ? '&hideXs=true' : '';
    layoutUrl += options ? `&options=${options}` : '';
    layoutUrl += marketid ? `&marketid=${marketid}` : '';
    layoutUrl += atb ? `&atb=${atb}` : '';
    layoutUrl += oconfig ? `&oconfig=${oconfig}` : '';

    utils.request.get(layoutUrl, (error, response) => {
      if (error) {
        this.mergeWithChildState({ app: { redirect: this.tryRedirect(), initial: true, postComplete: true, error: true } });
      } else {
        traffic.addPageRequest(packageId);
        this.mergeWithChildState({
          data: response,
          constants: {
            rp_personalization, // eslint-disable-line camelcase
            hivemind,
            sf_optimizations, // eslint-disable-line camelcase
            configdevmode,
            contextState,
            itc,
            src,
            plid,
            environment,
            layoutContent: response.layout.content,
            market: response.environment.market,
            currency: response.environment.currency,
            cartUrl: response.environment.cartUrl,
            domainApiUrl: response.environment.domainApiUrl,
            layoutUrl,
            postToCartUrl: response.environment.purchaseUrl,
            tmsKeyLinks: response.environment.tmsKeyLinks,
            experiments: response.environment.experiments,
            isInternal: response.environment.isInternal,
            options,
            htmlDir: pageVars.htmlDir,
            atb,
            oconfig: response.environment.oconfig,
            virtualPagePath: traffic.getPageVirtualPath(packageId)
          },
          app: {
            initial: true,
            postComplete: true,
            content: response.layout.content,
            pageContent: initProps && initProps.content || pageVars.content,
            updateInstanceUrl: response.environment.configUrl
          },
          pages: response.layout.pages,
          orderSummary: getOrderSummary(response)
        });

        this.setupInitialPage(this.state.pages);

        if (response.environment.isInternal) {
          global.StateApi = this; // useful to debug in browser console
        }
        this.sendExperimentsToTraffic();
      }

    });
  }

  sendExperimentsToTraffic() {
    const { experiments } = this.state.data.environment;
    if (experiments && experiments.HivemindExperiments && experiments.HivemindExperiments.length > 0) {
      experiments.HivemindExperiments.forEach(({ ExperimentId, CohortId }) => {
        const eId = `hivemind.fos.experiment.${ExperimentId}.${CohortId}.impression`;
        traffic.sendExperiments(ExperimentId, `abn`, CohortId, 'Hivemind');
        traffic.sendImpressions(eId);
      });
    }
  }

  getPageVariable() {
    window.cms = window.cms || {};
    window.cms.modules = window.cms.modules || {};
    window.cms.modules.reactAppVariables = window.cms.modules.reactAppVariables || {};
    window.cms.modules.reactAppVariables.configure = window.cms.modules.reactAppVariables.configure || {};
    window.cms.modules.reactAppVariables.configure.content = window.cms.modules.reactAppVariables.configure.content || {};

    window.reactAppVariables = window.reactAppVariables || {};
    window.reactAppVariables.configure = window.reactAppVariables.configure || {};
    window.reactAppVariables.configure.content = window.reactAppVariables.configure.content || {};

    const configure = window.cms.modules.reactAppVariables.configure || window.reactAppVariables.configure || {};

    if (document && document.querySelector) {
      configure.htmlDir = document.querySelector('html').getAttribute('dir') || 'ltr';
    }

    return configure;
  }

  updateInstance(name, value) {
    return new Promise((resolve, reject) => {
      const { data: { instance: instanceData } } = this.getState();

      const {
        itc,
        src,
        contextState,
        configdevmode = null,
        /* eslint-disable camelcase */
        rp_personalization = null,
        hivemind  = null,
        sf_optimizations = null,
        /* eslint-enable camelcase */
        atb = null,
        oconfig = false
      } = this.state.constants;

      const { updateInstanceUrl } = this.state.app;

      const instance = Object.assign({}, instanceData);

      let parseValue = value.toString() === 'false' ? 'none' : value;
      parseValue = parseValue.toString() === 'true' ? true : parseValue;
      parseValue = parseInt(parseValue, 10) == parseValue ? parseInt(parseValue, 10) : parseValue; // eslint-disable-line

      set(instance, name, parseValue);

      if (!this.getState('app').postComplete) return;

      this.mergeWithChildState({ app: { postComplete: false, error: false } }, () => {
        let url = updateInstanceUrl;
        url += itc ? `&itc=${itc}` : '';
        url += src ? `&src=${src}` : '';
        /* eslint-disable camelcase */
        url += rp_personalization ? `&rp_personalization=${rp_personalization}` : '';
        url += hivemind ? `&hivemind=${hivemind}` : '';
        url += sf_optimizations ? `&sf_optimizations=${sf_optimizations}` : '';
        /* eslint-enable camelcase */
        url += contextState ? `&contextState=${contextState}` : '';
        url += configdevmode ? `&configdevmode=${configdevmode}` : '';
        url += atb ? `&atb=${atb}` : '';
        url += oconfig ? `&oconfig=${oconfig}` : '';

        utils.request.post(url, instance, _options, (error, response) => {
          if (error) {
            this.mergeWithChildState({ app: { initial: false, postComplete: true, error: true } }, reject);
          } else {
            this.mergeWithChildState({
              data: response,
              app: {
                initial: true,
                postComplete: true,
                content: response.layout.content,
                updateInstanceUrl: response.environment.configUrl
              },
              pages: response.layout.pages,
              orderSummary: getOrderSummary(response, this.getStateValue)
            }, resolve);

            this.sendExperimentsToTraffic();
          }
        });
      });
    });
  }

  handleContinueClick(nextUrl = this.state.constants.cartUrl) {
    return new Promise((resolve, reject) => {
      const { pages } = this.state.data.layout;
      const { currentPage } = this.state.app;
      const { domainSearch } = this.state;
      const { selectedDomain = {} } = domainSearch;
      const newPageIndex = currentPage + 1;

      if (newPageIndex === pages.length && selectedDomain.restricted) {
        // remove domain from instance
        // domain will go through dpp flow if cart update is successful
        const newInstance = Object.assign({}, this.state.data.instance);
        this.updateCartWithInstance(newInstance, (cartUpdateSuccess, response) => {
          if (cartUpdateSuccess) {
            this.sendExpDataToTraffic(response);
            // package without domain was successfully
            this.goToNextPage('/dpx/registration?tmskey=dpp_skip_config');
          } else {
            // cart update failed
            this.mergeWithChildState({ app: { error: true, postComplete: true } }, reject);
          }
        });
      } else if (newPageIndex === pages.length) {
        // if there are no more pages
        this.updateCartWithInstance(this.state.data.instance, (success, response) => {
          if (success) {
            this.sendExpDataToTraffic(response);
            this.goToNextPage(nextUrl);
          } else {
            this.mergeWithChildState({ app: { error: true, postComplete: true } }, reject);
          }
        });
      } else if (pages[newPageIndex].page === 'free_domain_page') {
        window.scrollTo(window.scrollX, 0);
        this.mergeWithChildState({
          app: {
            currentPage: newPageIndex,
            showOrderSummary: false,
            showNoThanksFreeOfferButton: true
          }
        }, resolve);
      } else {
        window.scrollTo(window.scrollX, 0);
        this.mergeWithChildState({
          app: {
            currentPage: newPageIndex
          }
        }, resolve);
      }
    });
  }

  sendExpDataToTraffic(data) {
    if (data) {
      var exp;
      if (data.experimentationData) {
        exp = data.experimentationData;
        this.addItcToItems(exp);
        traffic.sendEvent(exp.schema, exp.type, exp.data);
      }

      if (data.deletedItemsExperimentationData) {
        exp = data.deletedItemsExperimentationData;
        this.addItcToItems(exp);
        traffic.sendEvent(exp.schema, exp.type, exp.data);
      }
    }
  }

  addItcToItems(expData) {
    if (urlParams.has('itc')) {
      var itc = urlParams.get('itc');
      if (expData.data && expData.data.items) {
        expData.data.items.forEach((i) => {
          i.itc = itc;
        });
      }
    }
  }

  updateCartWithInstance(instance, cb = () => {}) {
    const {
      constants: {
        postToCartUrl,
        itc,
        hivemind
      },
      data: {
        packageId
      }
    } = this.state;

    const itcParam = itc ? `&itc=${itc}` : '';
    const hivemindParam = hivemind ? `&hivemind=${hivemind}` : '';

    let url = postToCartUrl;
    url = `${postToCartUrl}${packageId}${itcParam}${hivemindParam}`;

    this.mergeWithChildState({ app: { initial: false, postComplete: false } }, () => {
      utils.request.post(url, instance, _options, (error, response = {}) => {
        if (error) {
          return cb(false, response);
        }
        cb(true, response);
      });
    });
  }

  getEid(section, widget, action) {
    return `mcx.sales.configure/${this.state.data.packageId}.${section}.${widget}.${action}`;
  }

  parseHtml(string) {
    const defaultTokens = [
      {
        key: 'icannfee',
        value: this.getStateValue('data.environment.icannFee')
      },
      {
        key: 'storefrontdisplayname',
        value: this.getStateValue('data.environment.storefrontDisplayName')
      },
      {
        key: 'fqdn',
        value: this.getStateValue('domainSearch.exactDomain.name')
      },
      {
        key: 'selectedterm',
        value: this.getTermData().numberOfTerms
      }
    ];
    defaultTokens.map(({ key, value }) => {
      string = string.replace(new RegExp(`{${key}}`, 'gi'), value);
    });
    return parse(string);
  }

  getTermData() {
    const { instance, packageId } = this.state.data;
    return (instance && packageId && instance[packageId].term) ? instance[packageId].term : {};
  }

  getTermDataByPackageId(packageId) {
    const { instance } = this.state.data;
    return (instance && packageId && instance[packageId].term) ? instance[packageId].term : {};
  }

  formatMicrounits(price) {
    return formatMicrounits(price, this.state.data.environment.market, this.state.data.environment.currency);
  }

  subscribe(name, cb) {
    this.lastSubscriptionId++;
    this.subscriptions[this.lastSubscriptionId] = { name: name, cb: cb };
    return this.lastSubscriptionId;
  }

  unsubscribe(subscriptionId) {
    delete this.subscriptions[subscriptionId];
  }

  notifySubscribers(mergeCb) {
    Object.values(this.subscriptions).forEach(({ name, cb }) => {
      if (typeof get(this.nextState, name) !== 'undefined') {
        return cb(get(this.state, name));
      }
    });
    this.nextState = {};
    mergeCb();
  }

  mergeWithState(stateChange = {}, cb = () => { }) {
    this.nextState = Object.assign({}, stateChange);
    this.state = Object.assign({}, this.state, stateChange);
    this.notifySubscribers(cb);
  }

  mergeWithChildState(stateChange = {}, cb = () => { }) {
    this.dataObject = mapData(stateChange.data || this.state.data);
    this.mergeWithState(getUpdatedMergedStateObjects(this.state, stateChange), cb);
  }

  getState(name = null) {
    if (typeof name === 'string') {
      return this.state[name];
    }
    return this.state;
  }

  getDataByInstancePath(instancePath) {
    return this.dataObject[instancePath] || {};
  }

  getInstanceByInstancePath(instancePath) {
    return get(this.state.data.instance, instancePath);
  }

  getStateValue(path) {
    return get(this.state, path);
  }

  normalizeEnvironment(env) {
    const envs = {
      development: 'development',
      dev: 'development',
      test: 'test',
      production: 'prod',
      prod: 'prod'
    };

    return envs[env] || 'development';
  }

  goToNextPage(url) {
    global.location.href = url;
  }

  validatePageProperties(page) {
    var validPage = false;

    if (page && page.groups) {
      page.groups.forEach((group) => {
        if (validPage) {
          return;
        }

        group.attributes.some((attribute) => {
          const displayValue = attribute.layoutProperties.display;
          if (displayValue && displayValue !== 'none') {
            validPage = true;
            return validPage;
          }
        });
      });
    }

    return validPage;
  }

  setupInitialPage(pages) {
    const pageCount = Object.keys(pages).length;

    if (pages && pageCount > 0) {
      for (let i = 0; i < pageCount; i++) {
        if (this.validatePageProperties(pages[i])) {
          this.mergeWithChildState({
            app: {
              showOrderSummary: !(pages[i].page === 'free_domain_page'),
              showNoThanksFreeOfferButton: pages[i].page === 'free_domain_page',
              currentPage: i
            }
          });

          return;
        }
      }

    }
  }
}

export default StateApi;
