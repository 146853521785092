const devHosts = [
  'dev-godaddy.com',
  'dev-secureserver.net'
];

const testHosts = [
  'test-godaddy.com',
  'test-secureserver.net'
];

const getEnvironment = () => {
  let environment;

  if (devHosts.some(host => window.location.hostname.includes(host))) {
    environment = 'development';
  }

  if (testHosts.some(host => window.location.hostname.includes(host))) {
    environment = 'test';
  }

  return environment || currentEnvironment.NODE_ENV || 'prod'; // eslint-disable-line no-undef
};

export default getEnvironment;
