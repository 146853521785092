import { Component } from 'react';
import { Button } from '@ux/uxcore2';
import PropTypes from 'prop-types';
import { provideContext } from '../util/storeProvider';
import { JsonComponent } from './JsonComponent';


class EnvironmentDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: '99%',
      open: false
    };
    this.handleScrollOrSize = this.handleScrollOrSize.bind(this);
    this.toggle = this.toggle.bind(this);
  }


  componentDidMount() {
  }

  componentWillUnmount() {
  }

  toggle() {
    let { open } = this.state;
    open = !open;
    this.setState({ open }, () => {
      this.handleScrollOrSize();
    });
  }

  handleScrollOrSize() {
    if (this.state.open) {
      this.setState({
        top: `${window.pageYOffset}px`,
        right: 0,
        left: 'unset'
      });
    } else {
      this.setState({
        top: `${window.pageYOffset}px`,
        left: '100%',
        right: '10px'
      });
    }
  }

  render() {
    const data = this.context.store.getState();
    return (
      <div>
        <div
          style={ !this.state.open ?
            { marginTop: '40px' } :
            {
              textAlign: 'left',
              position: 'fixed',
              top: 0,
              left: `${ this.state.left }`,
              right: `${ this.state.right }`,
              bottom: 0,
              width: '400px',
              padding: '10px',
              overflow: 'auto',
              backgroundColor: '#ddd',
              zIndex: 10000
            } }>
          <div className={ this.state.open ? '' : 'card ux-card' }>
            <div className={ this.state.open ? '' : 'card-block' }>
              <Button
                onClick={ this.toggle }
                design='inline'
                text='Internal Only'
              />
              <JsonComponent
                open={ [
                  'data',
                  'data.layout',
                  'data.layout.pages',
                  'data.instance',
                  'data.environment',
                  'data.environment.experiments',
                  'orderSummary',
                  'orderSummary.selections',
                  'constants'
                ] }
                name='state'
                data={ data }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EnvironmentDataComponent.propTypes = {
  containerDiv: PropTypes.object
};

export default provideContext(EnvironmentDataComponent);
