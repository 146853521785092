const getReferrerPathname = url => {
  if (url === '') return '';

  const linkElement = document.createElement('a');
  linkElement.href = url;

  return (linkElement.host === global.location.host ? linkElement.pathname : '');
};

const getPageVirtualPath = packageId => {
  const referrerPath = getReferrerPathname(global.document.referrer);

  let pagePath = global.location.pathname;
  const secondaryPathRegex = /(\/..|\/..-..)\/.*/.exec(pagePath);

  if (secondaryPathRegex) {
    pagePath = pagePath.replace(secondaryPathRegex[1], '');
  }

  const virtualPath = `${referrerPath}${pagePath}/${packageId}`;
  const extraSlashRegex = /\/?(\/*).*/.exec(virtualPath);
  const sanitizedPath = virtualPath.replace(extraSlashRegex[1], '');

  return sanitizedPath;
};

const addPageRequest = packageId => {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_page_request',
    version: 'v1',
    data: { virtual_path: getPageVirtualPath(packageId) }
  });
};

const addEvent = ({ eid, type = 'click' }) => {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_event',
    version: 'v1',
    data: {
      eid: eid,
      type: type
    }
  });
};

const sendEvent = (eventSchema, eventType, eventData) => {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: eventSchema,
    type: eventType,
    version: 'v1',
    data: eventData
  });
};

const sendExperiments = (experimentId, experimentType, variantId, experimentSource) => {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_experiment_assignment',
    type: experimentType,
    version: 'v1',
    data: {
      experiment_id: experimentId,
      variant_id: variantId,
      experiment_source: experimentSource
    }
  });
};

const sendImpressions = (eId) => {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_impression',
    version: 'v1',
    data: {
      eid: eId
    }
  });
};

const traffic = {
  addPageRequest,
  addEvent,
  sendEvent,
  sendExperiments,
  sendImpressions,
  getPageVirtualPath
};

export default traffic;
