import { styled } from '@linaria/react';
import { mediaMaxWidth } from './../styles/utils.js';

export const Styles = styled.li `  
  &.input-component:last-of-type {
    border-bottom-width: 0 !important;
    padding-bottom: 0 !important;
  }
  
  label {
      display: block;
  }
  .form-group {
    margin-bottom: 0;
      
    .ux-custom-control .ux-custom-target {
      margin-bottom: .5rem;
      display: flex;
       
      label {
        margin-block-start: -.25rem;
       
        flex: 1;
        cursor: pointer;
        ${ mediaMaxWidth.tablet } {
          margin-block-start: -0.0625rem;
        }
      }
    }
  }
  
  .input-indent {
    padding-inline-start: 2.25rem;
  }
  .attr-highlight-mesg {
    margin-right: calc(2.25rem - .5rem);
    margin-left: calc(2.25rem - .5rem);
  }
`;
