import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import getUnitPriceObject from './../util/unitPrice';
import InputComponent from './InputComponent';
import ChevronDown from '@ux/icon/chevron-down';
import { provideContext } from './../util/storeProvider';
import { Button } from '@ux/uxcore2';
import { IconBelowTextButton } from './Button.styles';
import { getInstancePathKey } from './../util/common';

export class AttributeComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visibleEnums: this.props.initialVisibleEnums,
      showDisclaimer: this.showDisclaimer()
    };
    this.handleShowMore = this.handleShowMore.bind(this);
  }

  handleShowMore() {
    this.setState({ visibleEnums: this.props.enum.length });
  }

  showDisclaimer() {
    const hasDisclaimerText = this.props.content.shared && this.props.content.shared['disclaimer-text'];
    return hasDisclaimerText;
  }

  render() {
    const {
      type,
      enum: enums = [],
      instancePath = '',
      layoutProperties = {},
      content = {},
      groupContent = {},
      content: { shared: contentShared = {} },
      selectedVal,
      maxPricePerMonthFormatted,
      minPricePerMonthFormatted,
      propertytype
    } = this.props;
    const title = { __html: contentShared.title } || {};
    const description = { __html: contentShared.description } || {};
    const {
      visibleEnums,
      showDisclaimer
    } = this.state;
    const { termType } = this.context.store.getTermDataByPackageId(instancePath.split('.')[0]);
    /*
      selectedValOffset is used when visibleEnum is less than enum.length
      to reduce visibleEnum value by 1 if the selected value is not yet visible
      in order to always show the selected enum and respect visibleEnum shown
    */
    let selectedValOffset = 1;

    return (
      <Fragment>
        <div className='pb-2 mb-2'>
          { title.__html && <h3 dangerouslySetInnerHTML={ title } /> }
          { description.__html && <p dangerouslySetInnerHTML={ description } /> }
          <ul className='list-unstyled'>
            {
              //  enums returning true continue to map function
              enums.filter(enumVal =>
                // enum does not contain content
                typeof content[enumVal] === 'object' &&
                // checkboxes toggle betweem checked/unchecked
                // checkbox 'none' does not need to be rendered
                !(layoutProperties.display === 'checkbox' && enumVal === 'none') &&
                !(layoutProperties.display === 'domainSearch' && enumVal === 'none')
              ).map((enumVal, i) => {
                if (i >= visibleEnums - selectedValOffset && enumVal !== selectedVal) return null;
                // selected value is visible and offset is no longer needed
                if (enumVal === selectedVal) {
                  selectedValOffset = 0;
                }

                return <InputComponent
                  includeState={ [{ path: 'app.postComplete' }] }
                  type={ type }
                  enumVal={ enumVal }
                  selectedVal={ selectedVal }
                  instancePath={ instancePath }
                  layoutProperties={ layoutProperties }
                  groupContent={ groupContent }
                  content={ content[enumVal] }
                  price={ getUnitPriceObject(this.props, enumVal, i) }
                  key={ getInstancePathKey(`${instancePath}.${enumVal}`, propertytype) }
                  quantifier={ contentShared.quantifier }
                  termType={ termType }
                />;
              })
            }
            {
              layoutProperties.display === 'priceOverride' &&
                <InputComponent
                  includeState={ [{ path: 'app.postComplete' }] }
                  selectedVal={ selectedVal }
                  instancePath={ instancePath }
                  layoutProperties={ layoutProperties }
                  content={ contentShared }
                  maxPricePerMonthFormatted={ maxPricePerMonthFormatted }
                  minPricePerMonthFormatted={ minPricePerMonthFormatted }
                />
            }
          </ul>
          {
            visibleEnums < enums.length &&

              <IconBelowTextButton className='show-more'>
                <Button
                  design='inline'
                  onClick={ this.handleShowMore }
                  tabIndex={ 0 }
                  text={ content.shared['show-more-label'] }
                  icon={ <ChevronDown width={ 16 } height={ 16 } /> }
                />
              </IconBelowTextButton>
          }
          {
            showDisclaimer &&
            <div className='row mr-0'>
              <div className='col-xs-12 att-disclaimer'>
                <p>
                  <span className='text-black'>
                    { content.shared['disclaimer-text'] }
                  </span>
                </p>
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

AttributeComponent.propTypes = {
  initialVisibleEnums: PropTypes.number,
  selectedVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  enum: PropTypes.array,
  name: PropTypes.string,
  propertytype: PropTypes.string,
  instancePath: PropTypes.string,
  layoutProperties: PropTypes.shape({
    display: PropTypes.string.required,
    modal: PropTypes.array,
    disclaimer: PropTypes.array
  }),
  groupContent: PropTypes.object,
  content: PropTypes.object,
  pkgqty: PropTypes.number,
  maxPricePerMonthFormatted: PropTypes.string,
  minPricePerMonthFormatted: PropTypes.string
};

export default provideContext(AttributeComponent);
