import { utils } from '@ux/uxcore2';

class DomainSearchApi {
  constructor(stateApi) {
    this.stateApi = stateApi;

    this.domainSearch = {
      apiKey: null,
      tmsKey: null,
      selectedDomain: {},
      exactDomain: {},
      domains: [],
      searchValue: ''
    };

    this.tryExecute = this.tryExecute.bind(this);
    this.callApi = this.callApi.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);
    this.cleanQuery = this.cleanQuery.bind(this);
    this.getDomainProductInfo = this.getDomainProductInfo.bind(this);
    this.done = this.done.bind(this);

    this.pageSize = 10;
    this.constantsSubscriptionId = this.stateApi.subscribe('constants', (constants) => {
      this.plid = constants.plid;
      this.apiUrl = constants.domainApiUrl;
      this.stateApi.unsubscribe(this.constantsSubscriptionId);
    });

    return {
      search: this.search.bind(this),
      verify: this.verify.bind(this)
    };
  }

  getApiUrl({ type, query }) {
    return `${this.apiUrl.replace('{0}', type)}` +
      `${ this.apiUrl.indexOf('?') === -1 ? '?' : '&' }q=${query}` +
      `&key=${this.domainSearch.apiKey}` +
      `&plid=${this.plid}` +
      `&pagesize=${this.pageSize}`;
  }

  callApi(url, cb) {
    utils.request.get(url, (error, response) => {
      if (error) {
        this.stateApi.mergeWithChildState({ app: { initial: false, postComplete: true, error: true } }, () => { cb(error); });
      }
      response = response || {};
      response.ExactMatchDomain = response.ExactMatchDomain || {};
      response.RecommendedDomains = response.RecommendedDomains || [];
      cb(response);
    });
  }

  search({ query, apiKey, tmsKey }, cb = () => {}) {
    query = this.cleanQuery(query);

    this.domainSearch = {
      apiKey: apiKey || this.domainSearch.apiKey,
      tmsKey: tmsKey || this.domainSearch.tmsKey,
      exactDomain: {},
      selectedDomain: {},
      domains: [],
      searchValue: query
    };

    this.tryExecute(query, () => {
      let url = this.getApiUrl({ type: 'exact', query, apiKey });
      this.callApi(url, ({ ExactMatchDomain, Products }) => {
        this.domainSearch.exactDomain = this.getDomainProductInfo(ExactMatchDomain, Products);
        this.domainSearch.match = (ExactMatchDomain.IsFree && ExactMatchDomain.IsAvailable);
        url = this.getApiUrl({ type: 'spins', query, apiKey });
        this.callApi(url, ({ RecommendedDomains, Products: RecommendedDomainsProducts }) => {
          this.domainSearch.domains = RecommendedDomains.map(domain =>
            this.getDomainProductInfo(domain, RecommendedDomainsProducts));
          this.done(cb);
        });
      });
    });
  }

  verify({ query, apiKey }, cb = () => {}) {
    query = this.cleanQuery(query);
    this.domainSearch.searchValue = query;
    this.tryExecute(query, () => {
      const url = this.getApiUrl({ type: 'exact', query, apiKey });
      this.callApi(url, ({ ExactMatchDomain, Products }) => {
        // TODO may need extra check for error handling or timeout
        const isFreeAndAvailable = ExactMatchDomain.IsFree && ExactMatchDomain.IsAvailable;
        this.domainSearch.selectedDomain = (isFreeAndAvailable) ?
          this.getDomainProductInfo(ExactMatchDomain, Products) : {};
        this.done(() => { cb(isFreeAndAvailable); });
      });
    });
  }

  tryExecute(query, cb) {
    if (this.stateApi.state.app.postComplete && query !== '') {
      this.stateApi.mergeWithChildState({ app: { postComplete: false, error: false } }, cb);
    }
  }

  done(cb) {
    this.stateApi.mergeWithChildState({ domainSearch: this.domainSearch, app: { postComplete: true } }, cb);
  }

  cleanQuery(query) {
    // TODO make sure this does not prevent i18n in the future
    return query.replace(/[`~!@#$%^&*()_|+\=?;:'",<>\{\}\[\]\\\/\s]/gi, ''); // eslint-disable-line no-useless-escape
  }

  getDomainProductInfo(domain, products) {
    const tldProduct = products.filter(p => (p.Tld === domain.Extension))[0];
    const restricted = (tldProduct.Content && tldProduct.Content.Header && tldProduct.Content.Header.length > 0) || false;
    return {
      name: domain.Fqdn,
      salePrice: this.stateApi.formatMicrounits(0),
      listPrice: tldProduct.PriceInfo.ListPriceDisplay,
      showIcannFee: tldProduct.HasIcannFee && domain.Price !== 0,
      restricted: restricted
    };
  }
}

export default DomainSearchApi;
