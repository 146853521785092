export const getUpdatedMergedStateObjects = (_old, _new) => {
  return Object.keys(_new).reduce((acc, cur) => {
    var a = _old[cur];
    var b = _new[cur];
    if (a && typeof a === 'object') {
      acc[cur] = Object.assign({}, a, b);
    } else {
      acc[cur] = b;
    }
    return acc;
  }, {});
};

export const convertToQuantityInstancePath = path => {
  return path.replace(/\.[^.]+$/, '.quantity');
};

export const convertToAddonQtyInstancePath = path => {
  return path.replace(/(?:\.[^.]+){2}$/, '.pkgQty.quantity');
};

export const getInstancePathKey = (key, propertytype = '') => {
  /*
    Key should be constant if the iteration is the same, to allow reselecting
    focus and avoid unnecessary rerendering.

    Keys built using instancePath allow this except when changing plans.
    Without this function an example of keys changing from plan tier3 to tier1

    keys with tier3 selected:
     - teir3.get-started.options.tier1
     - teir3.get-started.options.tier2
     - teir3.get-started.options.tier3 (selected)
    keys with tier1 selected:
     - teir1.get-started.options.tier1 (selected)
     - teir1.get-started.options.tier2
     - teir1.get-started.options.tier3
     With this function:
     - get-started.options.tier1
     - get-started.options.tier2
     - get-started.options.tier3
  */
  if (propertytype === 'swap') {
    return `${key.split('.').slice(1).join('.')}`;
  }
  return key;
};

/* eslint-disable */
/* polyfill from  https://developer.mozilla.org  */
/* istanbul ignore next */
Object.values = Object.values ? Object.values : o => Object.keys(o).map(k => o[k]);

/* istanbul ignore next */
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    }
    return this.indexOf(search, start) !== -1;

  };
}

/* istanbul ignore next */
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function (valueToFind, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(valueToFind, elementK) is true, return true.
        if (sameValueZero(o[k], valueToFind)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}

/* istanbul ignore next */
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}
/* eslint-enable */

export default {
  getUpdatedMergedStateObjects,
  convertToQuantityInstancePath,
  getInstancePathKey,
  convertToAddonQtyInstancePath
};
