import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import PageComponent from './PageComponent';
import { Button, Spinner, Alert } from '@ux/uxcore2';
import { provideContext } from './../util/storeProvider';
import EnvironmentDataComponent from './EnvironmentDataComponent';
import querystring from 'querystring';
import { StyledContainer, LoadingHeadline } from './App.styles.js';

export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.continueButtonDiv = React.createRef();
    this.containerDiv = React.createRef();
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.context.store.handleContinueClick();
  }

  render() {
    const {
      initial,
      redirect: redirectOnInitialError,
      error,
      showNoThanksFreeOfferButton = false,
      showOrderSummary = true,
      content = {},
      currentPage
    } = this.props;

    const { postComplete } = this.context;
    const disabled = !(initial || postComplete);

    const {
      apiError,
      continueToCart = '',
      loadingMessage,
      scrollToCart = ''
    } = content;

    const { content: pageContent } = this.context.store.getPageVariable();
    const { content: propContent } = this.context.store.initProps;
    const {
      redirectMessage = ''
    } = pageContent;
    const messages = propContent && Object.keys(propContent).length > 0 ? propContent : pageContent;

    const constants = this.context.store.getState('constants');

    const searchParams = querystring.parse(
      global.location &&
      global.location.search &&
      global.location.search.substring &&
      global.location.search.substring(1)
    );

    let environmentData = null;
    const { configdevmode } = searchParams;
    if (constants.isInternal && configdevmode) {
      environmentData = <EnvironmentDataComponent containerDiv={ this.containerDiv } />;
    }

    const {
      apiError: pageContentApiError = '',
      loadingMessage: pageContentLoadingMessage = ''
    } = messages;

    if (redirectOnInitialError) {
      const redirectDiv = <Alert
        dismissible={ false }>{ redirectMessage }</Alert>;

      return <StyledContainer className='redirect' postComplete={ postComplete }>
        { redirectDiv }{ environmentData }
      </StyledContainer>;
    }

    if (initial === null) { //eslint-disable-line
      return <StyledContainer postComplete={ postComplete }>
        <LoadingHeadline className='loading-headline'>
          <Spinner inline size='md'/> { loadingMessage || pageContentLoadingMessage }
        </LoadingHeadline>
        { environmentData }
      </StyledContainer>;
    }

    const alertDiv = <Alert
      dismissible={ false }>{ apiError || pageContentApiError }</Alert>;

    if (initial && error) {
      return <StyledContainer postComplete={ postComplete } className='error'>{ alertDiv }{ environmentData }</StyledContainer>;
    }

    const colClass = showOrderSummary ? 'col-sm-12 col-xl-8' : 'col-sm-12';

    // continueButtonDiv ref has to be attached before sending it as a prop to StickyComponent
    const continueBtn = <div className='row text-right' ref={ this.continueButtonDiv }>
      <div className={ colClass }>
        {
          showNoThanksFreeOfferButton ?
            null
            :
            <Button
              className='continue-button'
              data-eid={ this.context.store.getEid('continue', 'submit', 'click') }
              design='primary'
              disabled={ disabled }
              onClick={ this.handleContinue }
              text={ continueToCart }
            />
        }
      </div>
    </div>;

    return (
      <StyledContainer postComplete={ postComplete } forwardref={ this.containerDiv }>
        { error && alertDiv }
        <PageComponent
          includeState={ [{ path: 'pages', propKey: 'pages' }] }
          showOrderSummary={ showOrderSummary }
          continueButtonDiv={ this.continueButtonDiv }
          colClass={ colClass }
          scrollToCartText={ scrollToCart }
          currentPage={ currentPage }
          continueButton={ continueBtn }
        />
        { environmentData }
      </StyledContainer>
    );
  }
}

App.propTypes = {
  initial: PropTypes.bool,
  redirect: PropTypes.bool,
  error: PropTypes.bool,
  postComplete: PropTypes.bool,
  showNoThanksFreeOfferButton: PropTypes.bool,
  showOrderSummary: PropTypes.bool,
  content: PropTypes.shape({
    apiError: PropTypes.string,
    loadingMessage: PropTypes.string,
    continueToCart: PropTypes.string,
    total: PropTypes.string,
    included: PropTypes.string,
    legalTaxText: PropTypes.string,
    legalTermLengthText: PropTypes.string,
    scrollToCart: PropTypes.string,
    orderSummary: PropTypes.string,
    perMonth: PropTypes.string,
    perYear: PropTypes.string,
    perSite: PropTypes.string,
    per: PropTypes.string,
    monthPlural: PropTypes.string,
    yearPlural: PropTypes.string,
    noThanksOnFreeOffer: PropTypes.string,
    monthSingular: PropTypes.string,
    yearSingular: PropTypes.string,
    renewsAt: PropTypes.string,
    englishLangProductMesg: PropTypes.string,
    saleDiscount: PropTypes.string
  }),
  currentPage: PropTypes.number
};



export default provideContext(App);
