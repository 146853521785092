import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Button } from '@ux/uxcore2';

import { provideContext } from './../util/storeProvider';

const _instancePath = 'freedomain_attachment.free_domain'; // constant value

export class DomainSearchResultComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      failed: false
    };
    this.handleAction = this.handleAction.bind(this);
  }

  handleAction() {
    const domainName = this.props.domainProductInfo.name;
    this.context.store.domainSearchApi.verify({
      query: domainName,
      apiKey: this.props.groupContent.apiKey
    }, (isFreeAndAvailable) => {
      if (isFreeAndAvailable) {
        this.context.store.updateInstance(_instancePath, {
          options: 'domain_name',
          domainName: domainName
        }).then(() => {
          return this.context.store.handleContinueClick();
        }).catch(() => {
          this.setState({ failed: true });
        });
      } else {
        this.setState({ failed: true });
      }
    });
  }

  render() {
    const {
      resultType,
      domainProductInfo = {},
      groupContent = {},
      disabled
    } = this.props;

    const {
      selectContinueButton = ''
    } = groupContent;


    const {
      name: domainName,
      salePrice,
      listPrice,
      showIcannFee
    } = domainProductInfo;

    const { store } = this.context;

    if (this.state.failed) {
      return <div className={ `p-sm p-3 mb-2 card ux-card card-block flex-row flex-sm-wrap align-items-center` }>
        <div className=' h5 mb-0 flex-grow-1 text-muted break-word'>
          { groupContent.domainUnavailableForOfferTitle.replace('{fqdn}', domainName) }
        </div>
      </div>;
    }
    return (
      <div className={ `p-sm p-3 mb-2 card ux-card card-block flex-row flex-sm-wrap align-items-center` }>
        <div className=' h5 mb-0 flex-grow-1 text-muted break-word pr-2'>
          { domainName }
        </div>
        <div className='flex-grow-1 flex-sm-grow-0 h4 mb-0'>
          <span className='mr-4 d-inline-block'><strike>{ listPrice }</strike></span>
          <span className='text-primary mr-4 d-inline-block'>{ salePrice }{ showIcannFee ? '*' : '' }</span>
        </div>
        <div className='flex-grow-1 flex-sm-grow-0'>
          <Button
            className='btn-block'
            design='primary'
            data-eid={ store.getEid(`select_domain_${resultType}_result`, 'submit', 'click') }
            onClick={ this.handleAction }
            disabled={ disabled }
            text={ store.parseHtml(selectContinueButton) }
          />
        </div>
      </div>
    );
  }
}

DomainSearchResultComponent.propTypes = {
  resultType: PropTypes.string,
  groupContent: PropTypes.object.isRequired,
  domainProductInfo: PropTypes.shape({
    name: PropTypes.string,
    salePrice: PropTypes.string,
    listPrice: PropTypes.string,
    hasIcannFee: PropTypes.bool
  }),
  disabled: PropTypes.bool
};

export default provideContext(DomainSearchResultComponent);
