import CheckboxComponent from './CheckboxComponent';
import IntegerSelectorComponent from './IntegerSelectorComponent';
import RadioComponent from './RadioComponent';
import DomainSearchComponent from './DomainSearchComponent';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { provideContext } from './../util/storeProvider';
import { convertToQuantityInstancePath } from './../util/common';
import PriceOverrideComponent from './PriceOverrideComponent';

export class InputSwitchComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      enumVal,
      layoutProperties: { display },
      type,
      includeState
    } = this.props;
    const { store, postComplete } = this.context;
    const disabled = !postComplete;

    if (display === 'noinput') {
      return null;
    } else if (display === 'domainSearch') {
      return <DomainSearchComponent
        disabled={ disabled }
        { ...this.props }
        includeState={ [
          { path: 'app.content', propKey: 'commonContent' },
          { path: 'domainSearch', propKey: 'domainSearch' },
          ...includeState
        ] }
      />;
    } else if (display === 'checkbox' || type === 'boolean') {
      return <CheckboxComponent disabled={ disabled } { ...this.props } />;
    } else if (display === 'radio' || display === 'termSelector') {
      return <RadioComponent
        { ...this.props }
        includeState={ [{ path: 'app.content', propKey: 'commonContent' }, ...includeState] }
        disabled={ disabled }
      />;
    } else if (display === 'integer' || ((type === 'integer' || type === 'number') && enumVal)) {
      const quantityInstancePath = convertToQuantityInstancePath(this.props.instancePath);
      const { min, max } = store.getDataByInstancePath(quantityInstancePath);
      const qtyVal = store.getInstanceByInstancePath(quantityInstancePath);

      return <IntegerSelectorComponent
        min={ min }
        max={ max }
        disabled={ disabled }
        { ...this.props }
        instancePath={ quantityInstancePath }
        selectedVal={ qtyVal }
      />;
    } else if (display === 'priceOverride') {
      return <PriceOverrideComponent
        selectedVal={ this.props.selectedVal }
        instancePath={ this.props.instancePath }
        layoutProperties={ this.props.layoutProperties }
        { ...this.props }
        includeState={ [{ path: 'app.content', propKey: 'commonContent' }, ...includeState] }
        content={ this.props.content }
        maxPricePerMonthFormatted={ this.props.maxPricePerMonthFormatted }
        minPricePerMonthFormatted={ this.props.minPricePerMonthFormatted }
      />;
    }

    return null; // Ideally this should never happen
  }
}

InputSwitchComponent.propTypes = {
  type: PropTypes.string,
  enumVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  defaultVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  instancePath: PropTypes.string,
  layoutProperties: PropTypes.object,
  includeState: PropTypes.array,
  content: PropTypes.object,
  price: PropTypes.object,
  selectedVal: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  maxPricePerMonthFormatted: PropTypes.string,
  minPricePerMonthFormatted: PropTypes.string
};

export default provideContext(InputSwitchComponent);
