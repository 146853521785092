import PropTypes from 'prop-types';
import { Component } from 'react';
import { Sticky } from 'react-sticky';
import RenderContext, { provideContext } from './../util/storeProvider';
import ScrollDownToContinueComponent from './ScrollDownToContinueComponent';

export class StickyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCartButtonVisible: false,
      doSticky: true
    };
    this.handleScrollOrSize = this.handleScrollOrSize.bind(this);
    this.onClickScrollDownToCartHandler = this.onClickScrollDownToCartHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollOrSize);
    window.addEventListener('resize', this.handleScrollOrSize);
    this.handleScrollOrSize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollOrSize);
    window.removeEventListener('resize', this.handleScrollOrSize);
  }

  handleScrollOrSize(event) {
    if (event) {
      event.preventDefault();
    }
    let el = this.props.continueButtonDiv.current;
    if (el) {
      let top = el.offsetTop,
        left = el.offsetLeft;
      const width = el.offsetWidth,
        height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }
      const tolerance = 1;
      const isCartButtonVisible = (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height - tolerance) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
      );
      const doSticky = window.innerWidth > 1199;
      if (isCartButtonVisible !== this.state.isCartButtonVisible || doSticky !== this.state.doSticky) {
        this.setState({
          isCartButtonVisible: isCartButtonVisible,
          doSticky: doSticky
        });
      }
    }
  }

  onClickScrollDownToCartHandler(event) {
    event.preventDefault();
    const btn = this.props.continueButtonDiv || false;
    if (btn && btn.current) {
      btn.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }

  render() {
    if (!this.state.doSticky) {
      return (
        <div className='card ux-card card-block'>
          { this.props.children }
        </div>
      );
    }
    return <Sticky relative={ false }>{({ style }) =>
      <div style={ style }>
        <div className='card ux-card card-block'>
          { this.props.children }
          { !this.state.isCartButtonVisible &&
            <ScrollDownToContinueComponent
              dataEid={ this.context.store.getEid('ordersummary', 'scrolldown-arrow', 'click') }
              text={ this.props.text }
              action={ this.onClickScrollDownToCartHandler }
            />
          }
        </div>
      </div>}
    </Sticky>;
  }
}
StickyComponent.contextType = RenderContext;

StickyComponent.propTypes = {
  text: PropTypes.string.isRequired,
  continueButtonDiv: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default provideContext(StickyComponent);
