import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import AttributeComponent from './AttributeComponent';
import { provideContext } from './../util/storeProvider';
import Information from '@ux/icon/information';
import { getInstancePathKey } from './../util/common';

export class GroupComponent extends PureComponent {
  render() {
    const { group: groupName, content: groupContent, attributes, commonContent } = this.props;
    const title = { __html: groupContent.title } || {};
    const description = { __html: groupContent.description } || {};
    const notifyMesgKey = this.props.layoutProperties.notificationkey; // || 'englishLangProductMesg';

    const filteredAttributes = attributes.filter(attribute => attribute.name !== 'termType')
      .filter(attribute => attribute.name !== 'domainName')
      .filter(attribute => attribute.layoutProperties.display !== 'none')
      .filter(attribute => attribute.layoutProperties.display);

    if (filteredAttributes.length === 0) {
      return null;
    }

    const useCardBlock = !['free_domain'].includes(groupName);

    return (
      <div className={ useCardBlock ? 'card ux-card' : '' }>
        {
          notifyMesgKey &&
          commonContent[notifyMesgKey] &&
          groupName !== 'free_domain' &&
          <div className='ms-3 py-1 px-5 card-notify'>
            <Information width={ 12 } height={ 12 } />
            <span className='card-notify-text mx-2'>
              { commonContent[notifyMesgKey] }
            </span>
          </div>
        }
        <div className={ useCardBlock ? 'card-block' : '' }>
          { title.__html && <h3 dangerouslySetInnerHTML={ title } /> }
          { description.__html && <p dangerouslySetInnerHTML={ description } /> }
          {
            filteredAttributes.map((attribute) => {
              const enumLength = attribute.enum && attribute.enum.length ? attribute.enum.length : 0;
              const initialVisibleEnums = attribute.layoutProperties.initialVisibleEnums || enumLength;
              const selectedVal = this.context.store.getStateValue(`data.instance.${attribute.instancePath}`);
              return <AttributeComponent
                groupContent={ groupContent }
                includeState={ [{ path: 'app.postComplete' }] }
                key={ getInstancePathKey(attribute.instancePath, attribute.propertytype) }
                initialVisibleEnums={ initialVisibleEnums }
                selectedVal={ selectedVal }
                { ...attribute }
              />;
            })
          }
        </div>
      </div>
    );
  }
}

GroupComponent.propTypes = {
  attributes: PropTypes.array,
  content: PropTypes.object,
  group: PropTypes.string,
  layoutProperties: PropTypes.object,
  commonContent: PropTypes.object
};

export default provideContext(GroupComponent);
