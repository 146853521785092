import PropTypes from 'prop-types';
import { Button } from '@ux/uxcore2';
import { PureComponent } from 'react';
import ChevronDown from '@ux/icon/chevron-down';
import { IconBelowTextButton } from './Button.styles';


class ScrollDownToContinueComponent extends PureComponent {
  render() {
    const { action, dataEid, text } = this.props;
    return <IconBelowTextButton className='row text-center'>
      <Button data-eid={ dataEid }
        design='inline'
        onClick={ action }
        className='scroll-down-container text-center'
        text={ text }
        icon={ <ChevronDown width={ 16 } height={ 16 } /> }
      />
    </IconBelowTextButton>;
  }
}

ScrollDownToContinueComponent.propTypes = {
  action: PropTypes.func.isRequired,
  dataEid: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default ScrollDownToContinueComponent;
