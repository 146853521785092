export default function mapData({ layout }) {
  const dataObject = {};

  layout && layout.pages && layout.pages.map(page => {
    page && page.groups && page.groups.map(group => {
      group && group.attributes && group.attributes.map(attribute => {
        dataObject[attribute.instancePath] = attribute;
      });
    });
  });

  return dataObject;
}
