import { Component, Fragment } from 'react';
import { Modal, Button } from '@ux/uxcore2';
import PropTypes from 'prop-types';
import DomainSearchResultComponent from './DomainSearchResultComponent';
import { provideContext } from './../util/storeProvider';

class DomainResultsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  disclaimer() {
    const {
      disclaimerLinkText = '',
      disclaimerText = '',
      disclaimerTitle = ''
    } = this.props.content;

    if (disclaimerLinkText === '' || disclaimerText === '') return null;

    const modal = (this.state.modal) ?
      <Modal title={ disclaimerTitle } onClose={ () => { this.setState({ modal: false });} }>
        <div>{ this.context.store.parseHtml(disclaimerText) }</div>
      </Modal> : null;

    return (
      <Fragment>
        <Button
          className='disclaimer-link'
          aria-haspopup='true'
          data-eid={ this.context.store.getEid('domain_disclaimer', 'button', 'click') }
          design='inline'
          onClick={ () => { this.setState({ modal: true });} }
          text={ disclaimerLinkText }
        />
        { modal }
      </Fragment>
    );
  }


  render() {
    const { context: { store }, props } = this;
    const {
      content: groupContent = {},
      domainSearch = {}
    } = props;

    const {
      domainUnavailableSpinsTitle = '',
      domainUnavailableForOfferTitle = '',
      domainAvailableTitle = '',
      domainAvailableSpinsTitle = ''
    } = groupContent;

    const {
      exactDomain = {},
      domains = [],
      match = false
    } = domainSearch;

    if (domains.length === 0) return null;

    const showIcannFeeDisclaimer = domains.filter(x => x.showIcannFee).length > 0;

    return (
      <div className='mt-3'>
        {
          !match && exactDomain && exactDomain.name &&
          <h3> { store.parseHtml(domainUnavailableForOfferTitle) }</h3>
        }
        {
          match && exactDomain && exactDomain.name &&
          <Fragment>
            <h3>{ store.parseHtml(domainAvailableTitle) }</h3>
            <DomainSearchResultComponent
              { ...this.props }
              resultType='exact'
              groupContent={ groupContent }
              domainProductInfo={ exactDomain }
            />
          </Fragment>
        }
        { match && <div className='h4 mt-4'>{ domainAvailableSpinsTitle }</div> }
        { !match && <div className='h4 mt-4'>{ domainUnavailableSpinsTitle }</div> }
        {
          domains.map((domain, i) => {
            return <div key={ domain.name }>
              <DomainSearchResultComponent
                { ...this.props }
                resultType={ `spin-${i}` }
                groupContent={ groupContent }
                domainProductInfo={ domain }
              />

            </div>;
          })
        }
        {
          showIcannFeeDisclaimer && this.disclaimer()
        }
      </div>
    );
  }
}

DomainResultsComponent.propTypes = {
  disabled: PropTypes.bool,
  domainSearch: PropTypes.object,
  content: PropTypes.object
};

export default provideContext(DomainResultsComponent);
