import PropTypes from 'prop-types';
import { PureComponent, Fragment } from 'react';
import { provideContext } from '../util/storeProvider';

export class PriceComponent extends PureComponent {
  render() {
    if (!this.props.price) return null;

    const {
      enumVal = '',
      priceText,
      priceText2, // eslint-disable-line
      priceDisplay = 'default',
      price: {
        salepriceformatted,
        listpriceformatted,
        percentagesavings: percentsavings = 0,
        percentagesavingsformatted,
        renewalpriceformatted
      },
      termType = 'MONTH',
      perYear,
      perMonth,
      saleDiscount,
      quantifier = '',
      renewsAt,
      renewsSameDiscount
    } = this.props;

    const isOnSale = percentsavings > 0;
    const termTypeText = termType.toUpperCase() === 'YEAR' ? perYear : perMonth;
    const perTerm = quantifier === '' ? termTypeText : quantifier;
    const salePrice = isOnSale ? salepriceformatted : listpriceformatted;
    const renewsAtText = renewsAt &&
      renewsAt.replace('{listpriceformatted}', renewalpriceformatted).replace('{term}', perTerm);
    const renewsWithDiscountText = renewsSameDiscount && percentagesavingsformatted &&
      renewsSameDiscount.replace('{savingpercent}', percentagesavingsformatted);
    const saleText = saleDiscount && percentagesavingsformatted
      && saleDiscount.replace('{savingpercent}', percentagesavingsformatted);

    if (priceText) {
      return (
        <div>
          <div className='h4 price'>{ priceText }</div>
          {
            priceDisplay === 'savings-renews-at-price' &&
            <div className='text-muted'>{ renewsAtText }</div>
          }
        </div>
      );
    }

    if (enumVal.toString().toLowerCase() === 'none' || priceDisplay === 'removed' || priceDisplay === 'none') return null;

    return (
      <div>
        <span className='bold h4 price no-wrap-text'>{ salePrice }</span>
        <span className='text-muted bold'>{ perTerm }</span>
        {
          // $1.99/mo grey strike-thru text
          // On Sale (Save 20%)
          priceDisplay === 'default' &&
          isOnSale &&
          <Fragment>
            <div className='text-muted'>
              <strike>{ listpriceformatted }{ perTerm }</strike>
            </div>
            {
              <div className='text-muted'>{ saleText }</div>
            }
          </Fragment>
        }
        {
          // Renews at $1.99/mo
          priceDisplay === 'savings-renews-at-price' &&
          renewsAtText &&
          <div className='text-muted'>{ renewsAtText }</div>
        }
        {
          priceDisplay === 'renews-with-discount' &&
          renewsSameDiscount &&
          <div className='text-muted'>{ renewsWithDiscountText }</div>
        }
        {
          priceText2 &&
          <div className='text-muted'>{ priceText2 }</div>
        }
      </div>
    );
  }
}

PriceComponent.propTypes = {
  enumVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  priceText: PropTypes.string,
  priceText2: PropTypes.string,
  perYear: PropTypes.string,
  perMonth: PropTypes.string,
  perSite: PropTypes.string,
  quantifier: PropTypes.string,
  renewsAt: PropTypes.string,
  priceDisplay: PropTypes.string,
  price: PropTypes.shape({
    salepriceformatted: PropTypes.string,
    listpriceformatted: PropTypes.string,
    percentagesavings: PropTypes.int,
    percentagesavingsformatted: PropTypes.string,
    renewalpriceformatted: PropTypes.string
  }),
  termType: PropTypes.string,
  renewsSameDiscount: PropTypes.string,
  saleDiscount: PropTypes.string
};

export default provideContext(PriceComponent);
