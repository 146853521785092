import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import ButtonSet from '@ux/sibling-set';
import '@ux/sibling-set/styles';
import Button from '@ux/button';
import Modal from '@ux/modal';
import { provideContext } from './../util/storeProvider';

export const PopupModalTypes = {
  MODAL_TYPE_NONE: 'none',
  MODAL_TYPE_BASIC: 'Basic',
  MODAL_TYPE_CONFIRMATION: 'Confirmation'
};

export class PopupModalComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalShown: false
    };

    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.modal = this.modal.bind(this);
  }

  confirm(e) {
    const { confirmFunc = null } = this.props;

    if (confirmFunc) {
      this.setState({ modalShown: true }, () => confirmFunc(e));
    }

    this.close(e);
  }

  close(e) {
    const { cancelFunc } = this.props;

    if (cancelFunc) {
      cancelFunc(e);
    } else {
      this.setState({ modalShown: true });
    }
  }

  modal() {
    const {
      title,
      text,
      cancelText = '',
      confirmText = '',
      show,
      type = PopupModalTypes.MODAL_TYPE_BASIC
    } = this.props;
    const showModal = show && !this.state.modalShown;

    if (!showModal) {
      return null;
    }

    const footer = (
      <ButtonSet>
        <Button design='primary'
          onClick={ e => { this.confirm(e); } }
          text={ confirmText }
        />
        <Button design='secondary'
          onClick={ e => { this.close(e); } }
          text={ cancelText }
        />
      </ButtonSet>
    );

    return (
      <Modal
        title={ title }
        onClose={ e => { this.close(e); } }
        footer={ type === PopupModalTypes.MODAL_TYPE_CONFIRMATION ? footer : null }>
        <div dangerouslySetInnerHTML={{ __html: `${text}` }} />
      </Modal>
    );
  }

  render() {
    return (
      <Fragment>
        { this.modal() }
      </Fragment>
    );
  }
}

PopupModalComponent.propTypes = {
  cancelFunc: PropTypes.func,
  confirmFunc: PropTypes.func,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  type: PropTypes.string,
  show: PropTypes.bool.isRequired
};

export default provideContext(PopupModalComponent);
