import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Checkbox from '@ux/checkbox';
import '@ux/checkbox/styles';

import { provideContext } from './../util/storeProvider';

export class CheckboxComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(_, e) {
    const { checked } = e.event.target;
    const { handleChange, enumVal } = this.props;
    handleChange(checked ? enumVal : 'none');
  }

  render() {
    const { instancePath, content, enumVal, selectedVal, disabled } = this.props;

    const { store } = this.context;
    // intentionally not comparing type
    const checked = (selectedVal == enumVal) // eslint-disable-line
    return (
      <div>
        {
          content['feature-highlight-text'] &&
          <span className='attr-highlight-mesg bg-warning-highlight px-1'>
            <strong>{content['feature-highlight-text']}</strong>
          </span>
        }
        <Checkbox
          name={ instancePath }
          data-eid={ store.getEid(enumVal, 'checkbox', checked ? 'deselect' : 'select') }
          defaultChecked={ selectedVal }
          label={ <h5 className='mb-0'>{ store.parseHtml(content.title) }</h5> }
          onChange={ this.handleChange }
          checked={ checked }
          value={ checked ? enumVal : false }
          disabled={ disabled }
        />
      </div>
    );
  }
}

CheckboxComponent.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  selectedVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  instancePath: PropTypes.string.isRequired,
  layoutProperties: PropTypes.object,
  enumVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ])
};

export default provideContext(CheckboxComponent);
