import querystring from 'querystring';
import ReactDOM from 'react-dom';
import StateApi from './util/stateApi';
import ConfigPage from './ConfigPage';
import getEnvironment from './util/host';

const query = querystring.parse(global.location.search);

const initProps = {
  environment: getEnvironment(), // eslint-disable-line no-undef
  packageId: query.packageId || query.plan,
  plid: query.plid || query.privateLabelId || 1,
  xs: query.xs || 1,
  src: query.src || 'ac',
  itc: query.itc || null
};

const store = new StateApi();
store.initialize(initProps);
ReactDOM.render(<ConfigPage store={ store } />, document.getElementById('react-app'));
