import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import Button from '@ux/button';
import Tooltip from '@ux/tooltip';
import Help from '@ux/icon/help';
import '@ux/tooltip/styles';
import { provideContext } from './../util/storeProvider';

export class FeatureTextComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.tooltipRef = React.createRef(null);
    this.state = {
      showTooltip: false
    };
  }

  render() {
    const { store } = this.context;
    const { content, isMobile, indentClass = '' } = this.props;

    if (!content['feature-text'] && !content['feature-text-2']) return null;

    return (
      <div className='row'>
        <div className={ `col-xs-12 col-sm-11 feature-text-2 ${ indentClass } ${ isMobile ? 'mt-2' : '' }` }>
          <div className='mb-1'>
            { content['feature-text'] &&
              <Fragment>
                <b>
                  <span className='text-black'>{ store.parseHtml(content['feature-text']) }</span>
                </b><br/>
              </Fragment>
            }

            { content['feature-text-2'] &&
            store.parseHtml(content['feature-text-2']) }

            { content['tooltip-text'] &&
              <>
                <Button
                  ref={ this.tooltipRef }
                  onClick={ () => { this.setState({ showTooltip: !this.state.showTooltip }); } }
                  icon={ <Help /> }
                  aria-describedby='button-hint'
                />
                { this.state.showTooltip &&
                  <Tooltip
                    id='button-hint'
                    anchorRef={ this.tooltipRef }
                    open={ this.state.showTooltip }
                    onClose={ () => { this.setState({ showTooltip: false });} }
                    placement='top'
                  >
                    <p className='mb-0 text-left'>{ store.parseHtml(content['tooltip-text']) }</p>
                  </Tooltip>
                }
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

FeatureTextComponent.propTypes = {
  indentClass: PropTypes.string,
  isMobile: PropTypes.bool,
  content: PropTypes.object.isRequired
};

export default provideContext(FeatureTextComponent);
