import { styled } from '@linaria/react';

export const IconBelowTextButton = styled.div `  
  button {
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    align-items: center;
    width: 100%;
}
`;
