const has = (paramName) => {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(paramName);
};
const get = (paramName) => {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(paramName) ? urlParams.get(paramName) : null;
};

const urlParams = {
  get,
  has
};

export default urlParams;
