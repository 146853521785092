import PropTypes from 'prop-types';
import { PureComponent, Fragment } from 'react';
import OrderSummarySelectionComponent from './OrderSummarySelectionComponent';
import { provideContext } from './../util/storeProvider';

class OrderSummaryComponent extends PureComponent {
  render() {
    const {
      selections,
      totalPrice,
      commonContent: content } = this.props;

    const { store } = this.context;

    if (selections.length === 0) {
      return null;
    }

    return (
      <Fragment>
        <div className='row'>
          <div className='col-xs-12'>
            <h4 className='text-black'>{ content.orderSummary }</h4>
          </div>
        </div>
        <ul id='orderSummaryList' className='list-unstyled'>
          {
            selections.map((group, i) =>
              <OrderSummarySelectionComponent
                key={ `${i}SummaryCartItem` }
                { ...group }
              />)
          }
        </ul>
        <div className='row'>
          <div className='col-xs-5'>
            <p className='bold total-text'>{ content.total }</p>
          </div>
          <div className='col-xs-7 text-right'>
            <p id='totalPrice' className='bold total-price'>{ store.formatMicrounits(totalPrice) }</p>
          </div>
        </div>
        {
          (content.legalTaxText || content.legalTermLengthText) &&
          <div className='row'>
            <div className='card-disclaimer text-center mb-1 hidden-lg-down'>
              <p>
                { content.legalTaxText }<br />
                { content.legalTermLengthText }
              </p>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}

OrderSummaryComponent.propTypes = {
  selections: PropTypes.arrayOf(PropTypes.object),
  totalPrice: PropTypes.number,
  commonContent: PropTypes.shape({
    legalTaxText: PropTypes.string,
    legalTermLengthText: PropTypes.string,
    orderSummary: PropTypes.string,
    total: PropTypes.string
  })
};

export default provideContext(OrderSummaryComponent);
