import { PopupModalComponent } from './PopupModalComponent';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import PriceComponent from './PriceComponent';
import FeatureTextComponent from './FeatureTextComponent';
import InputSwitchComponent from './InputSwitchComponent';
import { provideContext } from './../util/storeProvider';
import { Styles } from './InputComponent.styles';

export class InputComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalShown: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    var { instancePath, layoutProperties: { display } } = this.props;
    const { hasModal, showModal, modalShown } = this.state;

    if (display === 'integer') {
      instancePath = instancePath.replace('options', 'quantity');
    }

    if (!modalShown && !showModal && hasModal) {
      this.setState({ showModal: true, modalShown: true });
    } else {
      this.context.store.updateInstance(instancePath, value);
    }
  }

  componentDidMount() {
    const { enumVal, content, layoutProperties: { modal = [] } } = this.props;
    this.setState({ hasModal: content['modal-text'] && modal.includes(enumVal) });
  }

  render() {
    const {
      content,
      enumVal,
      price,
      layoutProperties: { display, priceDisplay = 'default' },
      quantifier,
      termType
    } = this.props;
    const { hasModal } = this.state;
    const isMobile = this.context.isBrowser && ['mobile'].includes(this.context.breakpoint);
    const indentClass = !['domainSearch', 'noinput'].includes(display) ? 'input-indent' : '';

    let col1Class = 'text-default col-xs-12';
    let col2Class = `${ isMobile ? indentClass : 'text-right no-padding' } col-xs-12`;
    if (priceDisplay !== 'removed') {
      col1Class += ' col-sm-8 col-md-8';
      col2Class += ' col-sm-4 col-md-4';
    }

    const featureText = (display !== 'domainSearch') ?
      <FeatureTextComponent indentClass={ indentClass } content={ content } isMobile={ isMobile } /> :
      null;

    const priceComponent = <PriceComponent
      enumVal={ enumVal }
      priceDisplay={ priceDisplay }
      priceText={ content['price-text'] }
      priceText2={ content['price-text-2'] }
      includeState={ [{ path: 'data.layout.content' }] }
      price={ price }
      quantifier={ quantifier }
      termType={ termType } />;

    return (
      <Styles className='bd-b-1 mb-2 pb-2 input-component'>
        <div className='row mr-0'>
          <div className={ col1Class }>
            <InputSwitchComponent
              handleChange={ this.handleChange }
              { ...this.props }
            />
            { !isMobile && featureText }
          </div>
          {
            priceComponent &&
            <div className={ col2Class }>
              { priceComponent }
            </div>
          }
        </div>
        { isMobile && featureText }
        { this.state.showModal && hasModal &&
          <PopupModalComponent
            cancelFunc={ () => this.setState({ showModal: false }) }
            confirmFunc={ () => {
              this.setState({ showModal: false },
                () => this.handleChange()
              );
            }
            }
            title={ content['modal-title'] }
            text={ content['modal-text'] }
            cancelText={ content['modal-button-cancel'] }
            confirmText={ content['modal-button-confirm'] }
            type={ content['modal-type'] }
            show={ this.state.showModal } />
        }
      </Styles>
    );
  }
}

InputComponent.propTypes = {
  content: PropTypes.object.isRequired,
  selectedVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  instancePath: PropTypes.string.isRequired,
  layoutProperties: PropTypes.object,
  enumVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  price: PropTypes.object,
  quantifier: PropTypes.string,
  termType: PropTypes.string
};

export default provideContext(InputComponent);
