import PropTypes from 'prop-types';
import React, { Component } from 'react';
import QuantitySelector from '@ux/quantity-selector';
import '@ux/quantity-selector/styles';
import debounce from 'lodash.debounce';

import { provideContext } from './../util/storeProvider';

export class IntegerSelectorComponent extends Component {
  constructor(props) {
    super(props);
    this.qtyRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, isValid) {
    const { handleChange, selectedVal, min, max } = this.props;
    if (isValid && value !== selectedVal && value !== '') {
      handleChange(value);
    } else if (value < min) {
      this.qtyRef.current._setValue(min, true);
    } else if (value > max) {
      this.qtyRef.current._setValue(max, true);
    } else if (value !== selectedVal) {
      this.qtyRef.current._setValue(selectedVal, true);
    }
  }

  render() {
    const { store } = this.context;
    var {
        instancePath,
        content: {
          title = '',
          errorMessage = ''
        },
        selectedVal,
        disabled,
        min,
        max
      } = this.props,
      debounceTimeout = 500;


    return (
      <QuantitySelector
        ref={ this.qtyRef }
        name={ instancePath }
        min={ min }
        max={ max }
        errorMessage={ errorMessage }
        defaultValue={ selectedVal }
        onChange={ debounce(this.handleChange, debounceTimeout) }
        label={ <h5 className='mb-0'>{ store.parseHtml(title) }</h5> }
        disabled={ disabled }
      />
    );
  }
}

IntegerSelectorComponent.propTypes = {
  disabled: PropTypes.bool,
  content: PropTypes.object.isRequired,
  instancePath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  selectedVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ])
};

export default provideContext(IntegerSelectorComponent);
