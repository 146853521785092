import PropTypes from 'prop-types';
import { Component } from 'react';
import Radio from '@ux/radio';
import '@ux/radio/styles';
import { provideContext } from './../util/storeProvider';

const getTermTypeText = (termType, content, numberOfTerms) => {
  let termTypeText;

  switch (termType) {
    case 'MONTH':
      termTypeText = numberOfTerms > 1 ? content.monthPlural : content.monthSingular;
      break;
    case 'YEAR':
      termTypeText = numberOfTerms > 1 ? content.yearPlural : content.yearSingular;
      break;
    default:
      termTypeText = '';
      break;
  }

  return termTypeText;
};

export class RadioComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(_, e) {
    const { value } = e.event.target;
    const { handleChange } = this.props;
    handleChange(value || 'none');
  }

  render() {
    const {
      instancePath,
      content,
      enumVal,
      selectedVal,
      disabled,
      layoutProperties,
      termType,
      commonContent
    } = this.props;

    const { store } = this.context;

    // intentionally not comparing type
    const checked = (selectedVal == enumVal); // eslint-disable-line

    let title = content.title;
    let id = instancePath + '.' + title;
    id = id.replace(' ', '-');

    // TODO: Remove ternary once content.title supports {termType}
    if (layoutProperties.display === 'termSelector') {
      title = content.title && content.title.includes('{termType}')
        ? content.title.replace('{termType}', getTermTypeText(termType, commonContent, enumVal)).trim()
        : content.title;
    }

    return (
      <div>
        {
          content['feature-highlight-text'] &&
          <span className='attr-highlight-mesg bg-warning-highlight px-1'>
            <strong>{ content['feature-highlight-text'] }</strong>
          </span>
        }
        <Radio
          className={ checked ? 'selected' : '' }
          id={ id }
          name={ instancePath }
          data-eid={ store.getEid(enumVal, 'radio', checked ? 'deselect' : 'select') }
          label={ <h5 className='mb-0'>{ title }</h5> }
          onChange={ this.handleChange }
          checked={ checked }
          value={ enumVal == 'none' ? false : enumVal } // eslint-disable-line
          disabled={ disabled }
        />
      </div>
    );
  }
}

RadioComponent.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  commonContent: PropTypes.object.isRequired,
  selectedVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  instancePath: PropTypes.string.isRequired,
  layoutProperties: PropTypes.object,
  enumVal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  termType: PropTypes.string
};

export default provideContext(RadioComponent);
