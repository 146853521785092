import PropTypes from 'prop-types';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';

export const LoadingHeadline = styled.div`{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 100px auto;
}
`;


export const container = css`
  margin: 40px auto 60px;
  
  .bold {
    font-weight: bold;
  }
`;


// (declare postComplete to keep it out of rest obj)
const Container = ({ className, forwardref, postComplete, ...rest }) => // eslint-disable-line no-unused-vars
  <div ref={ forwardref } className={ `container ${container} ${ className }` } { ...rest }/>;

Container.propTypes = {
  className: PropTypes.string,
  forwardref: PropTypes.object,
  postComplete: PropTypes.bool
};

export const StyledContainer = styled(Container)`
  cursor: ${ ({ postComplete }) => {
    if (postComplete) {
      return 'default';
    }
    return 'wait';
  } };
`;
