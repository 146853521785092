import PropTypes from 'prop-types';
import { createContext, PureComponent } from 'react';

const RenderContext = createContext({});

export default RenderContext;

const provideContext = WrappedComponent => {
  const ComponentName = WrappedComponent.displayName || WrappedComponent.name || 'WrappedComponent';

  class WithContext extends PureComponent {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <RenderContext.Consumer>
          {
            context => {
              const props = {};

              if (this.props.includeState) {
                this.props.includeState.map(({ path, propKey }) => {
                  if (path === 'breakpoint') {
                    props.breakpoint = this.props.breakpoint;
                  } else if (propKey) {
                    props[propKey] = context.store.getStateValue(path);
                  } else {
                    Object.assign(props, context.store.getStateValue(path));
                  }
                });
              }

              WrappedComponent.contextType = RenderContext;

              return <WrappedComponent
                { ...props }
                { ...this.props }
              />;
            }
          }
        </RenderContext.Consumer>
      );
    }
  }

  WithContext.propTypes = {
    includeState: PropTypes.array,
    breakpoint: PropTypes.string
  };

  WithContext.contextType = RenderContext;
  WithContext.displayName = `${ComponentName}Container`;

  return WithContext;
};

const Provider = RenderContext.Provider;
const Consumer = RenderContext.Consumer;

export {
  provideContext,
  Provider,
  Consumer
};
