(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom"), require("ux"), require("react"), require("prop-types"), require("@ux/text")["default"], require("@ux/button")["default"], require("@ux/modal")["default"]);
	else if(typeof define === 'function' && define.amd)
		define(["react-dom", "ux", "react", "prop-types", "@ux/text", "@ux/button", "@ux/modal"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react-dom"), require("ux"), require("react"), require("prop-types"), require("@ux/text")["default"], require("@ux/button")["default"], require("@ux/modal")["default"]) : factory(root["ReactDOM"], root["ux"], root["React"], root["PropTypes"], root["ux"]["Text"], root["ux"]["Button"], root["ux"]["Modal"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__994__, __WEBPACK_EXTERNAL_MODULE__3249__, __WEBPACK_EXTERNAL_MODULE__3899__, __WEBPACK_EXTERNAL_MODULE__189__, __WEBPACK_EXTERNAL_MODULE__8722__, __WEBPACK_EXTERNAL_MODULE__2117__, __WEBPACK_EXTERNAL_MODULE__4894__) => {
return 