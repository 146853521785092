const config = {
  development: {
    env: 'development',
    getLayoutUrl: (hostname, plid) => `https://salesproducts.api.dev-${hostname}/v1/pl/${plid}/configure?packageId=`
  },
  test: {
    env: 'test',
    getLayoutUrl: (hostname, plid) => `https://salesproducts.api.test-${hostname}/v1/pl/${plid}/configure?packageId=`
  },
  prod: {
    env: 'prod',
    getLayoutUrl: (hostname, plid) => `https://salesproducts.api.${hostname}/v1/pl/${plid}/configure?packageId=`
  }
};

export default config;
