import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { provideContext } from './../util/storeProvider';

class OrderSummarySelectionComponent extends PureComponent {
  render() {
    const {
      title,
      unitPrice,
      price,
      subTitle,
      term,
      termDisplay,
      unitPerTermDisplay,
      unitTypeDisplay,
      isInteger,
      qty,
      isOneTimeFee
    } = this.props;

    const { store } = this.context;

    let qtySummary = '';

    if (isInteger && qty && qty > 0) {
      const unitTypeText = unitTypeDisplay ? ` ${unitTypeDisplay}` : '';

      qtySummary = (
        <div>
          { qty }{ unitTypeText } x { store.formatMicrounits(unitPrice)}{unitPerTermDisplay}
        </div>
      );
    }

    const durationText = isOneTimeFee ? termDisplay : `${term} ${termDisplay}`;

    return (
      <li className='bd-b-1 mb-2 pb-2'>
        <div className='text-black'>
          <div className='row'>
            <div className='col-xs-5 col-lg-7'>
              <div className='item-title bold'>{ title }</div>
            </div>
            <div className='col-xs-7 col-lg-5 text-right'>
              <div className='item-price bold item-total-price text-black'>
                { store.formatMicrounits(price) }
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xs-5 col-lg-7'>
              { subTitle && <div className='item-subtitle text-muted'>{ store.parseHtml(subTitle) }</div> }
              { qtySummary }
            </div>
            <div className='col-xs-7 col-lg-5 text-right'>
              <div className='item-term text-muted bold'>{ durationText }</div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

OrderSummarySelectionComponent.propTypes = {
  title: PropTypes.string,
  unitPrice: PropTypes.number,
  price: PropTypes.number,
  subTitle: PropTypes.string,
  term: PropTypes.number,
  termDisplay: PropTypes.string,
  unitPerTermDisplay: PropTypes.string,
  unitTypeDisplay: PropTypes.string,
  isInteger: PropTypes.bool,
  qty: PropTypes.number,
  isOneTimeFee: PropTypes.bool
};

export default provideContext(OrderSummarySelectionComponent);
