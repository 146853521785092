export const breakpoints = {
  mobile: 519,
  phablet: 767,
  tablet: 991,
  desktop: 1199,
  large: 1719,
  xlarge: Infinity
};

export const getBreakpoint = () => {
  if (window && window.innerWidth) {
    const width = window.innerWidth;
    if (width <= breakpoints.mobile) {
      return 'mobile';
    }
    if (width <= breakpoints.phablet) {
      return 'phablet';
    }
    if (width <= breakpoints.tablet) {
      return 'tablet';
    }
    if (width <= breakpoints.desktop) {
      return 'desktop';
    }
    if (width <= breakpoints.large) {
      return 'large';
    }
    return 'xlarge';
  }
  return null;
};

export const isMobile = () => {
  const breakpoint = getBreakpoint();
  return (breakpoint === breakpoints.mobile ||
    breakpoint === breakpoints.phablet);
};
