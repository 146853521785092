import get from 'lodash.get';
import getUnitPriceObject from './unitPrice';
import {
  convertToQuantityInstancePath,
  convertToAddonQtyInstancePath
} from './common';

const getGroupName = group => group.group.split('.')[0];
const isNotPlanGroup = (basePackageId, group) => basePackageId !== getGroupName(group);
const isSelected = (instance, attribute) => {
  const instanceValue = get(instance, attribute.instancePath);
  return instanceValue !== 'none' && instanceValue !== 0;
};
const isNotTermSelectorType = (group, attribute) => group.group !== 'termSelector' && attribute.name !== 'termType';
const isNotPkgQtyType = (group, attribute) => group.group !== 'quantity' && attribute.name !== 'quantity';
const isNotDomainName = (attribute) => attribute.name !== 'domainName';
const isNotFreedomainAttachment = (group) => group.group !== 'freedomain_attachment.free_domain';
const shouldOrderSummaryDisplay = attribute => attribute.layoutProperties.orderSummaryDisplay !== 'none';

const getTermTypeToBeDisplayed = (termType, numberOfTerms, layout) => {
  const termDisplay = {};

  if (termType === 'UNKNOWN') {
    termDisplay.fullWord = layout.content.oneTimeFee;
    termDisplay.perAbbreviation = '';
  } else if (termType === 'MONTH' && numberOfTerms > 1) {
    termDisplay.fullWord = layout.content.monthPlural;
    termDisplay.perAbbreviation = layout.content.perMonth;
  } else if (termType === 'MONTH' && numberOfTerms === 1) {
    termDisplay.fullWord = layout.content.monthSingular;
    termDisplay.perAbbreviation = layout.content.perMonth;
  } else if (termType !== 'MONTH' && numberOfTerms > 1) {
    termDisplay.fullWord = layout.content.yearPlural;
    termDisplay.perAbbreviation = layout.content.perYear;
  } else if (termType !== 'MONTH' && numberOfTerms === 1) {
    termDisplay.fullWord = layout.content.yearSingular;
    termDisplay.perAbbreviation = layout.content.perYear;
  }
  return termDisplay;
};

const getUnitTypeDisplay = (quantity, attribute) => {
  const content = attribute && attribute.content && attribute.content.shared || {};

  if (!content['unit-type-singular'] || !content['unit-type-plural']) {
    return null;
  }

  return quantity === 1 ? content['unit-type-singular'] : content['unit-type-plural'];
};

const getOrderSummary = ({ instance, basePackageId, packageId, layout }, getStateValue = () => ('')) => {
  let totalPrice = 0;
  const { term: primaryPackageTerm } = instance[packageId];
  const selections = [];

  const getCorrectTermAndTermType = attribute => {
    const instancePathParent = attribute.instancePath.split('.')[0];
    const { term: attributeTerm } = instance[instancePathParent];

    // Attachments have fixedNumberOfTerms and their termType is always MONTH.
    if (attribute.fixedNumberOfTerms) {
      return {
        term: attribute.fixedNumberOfTerms,
        termType: 'MONTH'
      };
    }

    // We don't want to default termType to MONTH because in all scenarios except above, term object should have both
    // term and termType. If not, it is a bug in the system.
    return {
      term: attributeTerm.numberOfTerms || primaryPackageTerm.numberOfTerms,
      termType: attributeTerm.termType || primaryPackageTerm.termType
    };
  };

  layout.pages[0].groups
    .filter(group => isNotPlanGroup(basePackageId, group))
    .filter(group => isNotFreedomainAttachment(group))
    .map(group => {
      group.attributes && group.attributes
        .filter(attribute => isSelected(instance, attribute))
        .filter(attribute => isNotTermSelectorType(group, attribute))
        .filter(attribute => isNotPkgQtyType(group, attribute))
        .filter(attribute => isNotDomainName(attribute))
        .filter(attribute => shouldOrderSummaryDisplay(attribute))
        .map(attribute => {
          attribute.enum && attribute.enum
            .filter(enumVal => enumVal !== 'none')
            .filter(enumVal => enumVal !== 'disabled')
            .map((enumVal, j) => {
              let qty = 1; // qty of one is assumed unless there is an integer selector
              let instancePath = attribute.instancePath;
              let isInteger = false;

              if (attribute.productType === 'addOn' && enumVal === get(instance, instancePath)) {
                const addOnInstancePath = convertToAddonQtyInstancePath(attribute.instancePath);
                const pkgQty = get(instance, addOnInstancePath);
                qty = pkgQty || 1;
                isInteger = !!pkgQty;
              }

              if (attribute.layoutProperties.display && attribute.layoutProperties.display === 'integer') {
                instancePath = convertToQuantityInstancePath(attribute.instancePath);
                qty = get(instance, instancePath) || 0;
                isInteger = true;
              }

              if (attribute.content[enumVal] &&
                (enumVal === get(instance, instancePath) || (qty > 0 && isInteger))
              ) {
                const { term, termType } = getCorrectTermAndTermType(attribute);
                const displayableTerm = getTermTypeToBeDisplayed(termType, term, layout);
                const unitPriceObject = getUnitPriceObject(attribute, enumVal, j);
                const unitPrice = attribute.isFree
                  ? 0
                  : (unitPriceObject && unitPriceObject.sale || 0);
                const price = unitPrice * term * qty;
                const unitTypeDisplay = getUnitTypeDisplay(qty, attribute);
                const summaryDisplayOrder = attribute.layoutProperties.summaryDisplayOrder || 0;
                const isOneTimeFee = termType === 'UNKNOWN';

                totalPrice += price;
                // special case get content from layout page content
                const content = (attribute.name === 'numberOfTerms') ? layout.pages[0].content : attribute.content[enumVal];
                let subTitle = content['product-description'];
                if (instancePath.indexOf('free_domain_search') > -1) {
                  subTitle = getStateValue('domainSearch.selectedDomain.name');
                  if (typeof subTitle === 'undefined' || subTitle.length === 0 || enumVal.indexOf('disabled') > -1) return;
                }

                selections.push({
                  title: content['product-name'],
                  subTitle,
                  unitPrice,
                  price,
                  term,
                  qty,
                  isInteger,
                  termDisplay: displayableTerm.fullWord,
                  unitPerTermDisplay: displayableTerm.perAbbreviation,
                  unitTypeDisplay,
                  summaryDisplayOrder,
                  isOneTimeFee
                });
              }
            });
        });
    });

  selections.sort((a, b) => (a.summaryDisplayOrder - b.summaryDisplayOrder));


  return {
    selections,
    totalPrice,
    termType: primaryPackageTerm ? primaryPackageTerm.termType : null,
    numberOfTerms: primaryPackageTerm ? primaryPackageTerm.numberOfTerms : null,
    content: layout.content
  };
};

export default getOrderSummary;
