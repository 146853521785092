import PropTypes from 'prop-types';
import { PureComponent }  from 'react';
import { Button, Modal } from '@ux/uxcore2';
import { provideContext } from './../util/storeProvider';

export class DisclaimerComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  render() {
    const {
      disclaimerLinkText = '',
      disclaimerTitle = '',
      disclaimerContent = ''
    } = this.props;

    if (!disclaimerLinkText || !disclaimerContent) return null;

    return (
      <div>
        <Button
          className='disclaimer-link'
          aria-haspopup='true'
          design='inline'
          onClick={ () => { this.setState({ modal: true });} }
          text={ disclaimerLinkText }
        />
        { this.state.modal &&
          <Modal title={ disclaimerTitle } onClose={ () => { this.setState({ modal: false });} }>
            <p dangerouslySetInnerHTML={{ __html: disclaimerContent }}/>
          </Modal>
        }
      </div>
    );
  }
}

DisclaimerComponent.propTypes = {
  disclaimerLinkText: PropTypes.string,
  disclaimerTitle: PropTypes.string,
  disclaimerContent: PropTypes.string
};

export default provideContext(DisclaimerComponent);
