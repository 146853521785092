import PropTypes from 'prop-types';
import { PureComponent }  from 'react';

export class JsonComponent extends PureComponent {
  constructor(props) {
    super(props);
    const { open = [] } = props;
    this.state = {
      open: open
    };

    this.toggle = this.toggle.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.Node = this.Node.bind(this);
  }

  isObject(data) {
    return Object.prototype.toString.call(data) === '[object Object]';
  }

  isOpen(namespace) {
    return (this.state.open.includes(namespace));
  }

  toggle(e) {
    const open = [...this.state.open];
    const { currentTarget } = e;
    if (currentTarget) {
      const namespace = currentTarget.getAttribute('namespace') ;
      console.log(namespace); // eslint-disable-line
      if (this.isOpen(namespace)) {
        const index = open.findIndex((v) => v === namespace);
        open.splice(index, 1);
      } else {
        open.push(namespace);
      }
      this.setState({ open });
    }
  }

  Node(props) {
    const { Node } = this;
    const { data, namespace = [], name } = props;
    let ObjectComponent;
    let ValueComponent;
    const isObject = this.isObject(data);
    const isArray = Array.isArray(data);

    if (isObject) {
      ObjectComponent = Object.keys(data).map((key) => {
        const thisnamespace = [...namespace, key];
        return <Node key={ thisnamespace.join('.') } namespace={ thisnamespace } name={ key } data={ data[key] } />;
      });
    } else if (isArray) {
      ObjectComponent = data.map((entry, i) => {

        const thisnamespace = [...namespace, i];
        return <Node key={ thisnamespace.join('.') } namespace={ thisnamespace } name={ i } data={ entry } />;
      });
    } else if (data === null) { // eslint-disable-line
      ValueComponent = 'null';
    } else if (data === undefined) { // eslint-disable-line
      ValueComponent = 'undefined';
    } else {
      ValueComponent = data.toString();
    }

    const collapsable = (isObject || isArray) && namespace.length > 0 && ObjectComponent.length > 0;

    const isCollapsed = !this.isOpen(namespace.join('.'));
    let symbol = ' ';
    if (isCollapsed && collapsable) {
      symbol = '+';
    } else if (collapsable) {
      symbol = '-';
    }
    const label = <button
      style={{ border: 'none', fontWeight: 'bold', backgroundColor: 'transparent' }}
      // eslint-disable-next-line react/no-unknown-property
      namespace={ namespace.join('.') }
      onClick={ this.toggle }>
      { symbol }
      { name }</button>;



    if (isCollapsed && collapsable) {
      ValueComponent = <span style={{ color: '#999' }} > { ObjectComponent.length } </span>;
    }

    return (
      <div style={{ marginLeft: '20px', borderLeft: 'solid 1px #bbb' }}>
        <span>{label}: </span>
        { isObject && <span>{'{'}</span> }
        { isArray && <span>{'['}</span> }
        { !ValueComponent && ObjectComponent }
        { ValueComponent }

        { isObject && <span>{'}'}</span>  }
        { isArray && <span>{']'}</span> }
      </div>
    );
  }

  render() {
    const { Node } = this;
    return <Node { ...this.props } />;
  }
}


JsonComponent.propTypes = {
  open: PropTypes.array,
  namespace: PropTypes.array,
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object
  ])
};


export default JsonComponent;
